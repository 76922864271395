<template>
  <v-container
    fluid
    tag="section"
  >
    <div v-if="!isMaster">
      Kein Zugriff!
    </div>
    <v-card v-else flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="create"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :items="person"
        :headers="[
          { text: 'Nachname', value: 'familyName', sortable: true },
          { text: 'Vorname', value: 'givenName', sortable: true },
          { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
        ]"
        mobile-breakpoint="0"
        :items-per-page="15"
        @click:row="open"
      >
        <template #item.delete="{item}">
          <v-btn
            fab
            text
            small
            @click.stop="del(item._id)"
          >
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <person-edit
      v-if="dialog.id"
      v-model="dialog.open"
      :id="dialog.id"
    />
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'

const query = `
  _id
  familyName
  givenName
`

export default {
  name: 'person',

  components: {
    PersonEdit: () => import('../dialogs/person')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    PersonFind: [],
    filter: '',
    dialog: {
      open: false,
      id: null
    }
  }),

  computed: {
    ...mapGetters(['isMaster']),
    person () {
      return this.PersonFind.slice().sort((a, b) => {
        if (a.familyName !== b.familyName) return a.familyName < b.familyName ? -1 : 1
        return a.givenName < b.givenName ? -1 : 1
      })
    }
  },

  methods: {
    open (item) {
      this.dialog.id = item._id
      this.dialog.open = true
    },
    create () {
      this.mutate({
        mutation: gql`
          mutation {
            PersonCreate(familyName: "${this.filter || 'AAA Neuer Nutzer'}", givenName: "${this.filter || 'AAA Neuer Nutzer'}") { _id }
          }
        `
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) {
            PersonDelete(id: $id)
          }
        `,
        variables: {
          id
        }
      })
    }
  },

  apollo: {
    PersonFind: {
      query: gql`
        query($limit: Int, $name: String) {
          PersonFind(name: $name, limit: $limit) { ${query} }
        }`,
      variables () {
        return {
          limit: 1000,
          name: this.filter
        }
      },
      skip () {
        return this.filter.length < 4
      },
      subscribeToMore: {
        document: gql`
          subscription { PersonCreate { ${query} }}
          `,
        updateQuery: updateQuery('PersonFind', 'PersonCreate')
      }
    },
    $subscribe: {
      PersonUpdate: {
        query: gql`
          subscription { PersonUpdate { ${query} }}
          `
      },
      PersonDelete: {
        query: gql`
          subscription { PersonDelete }
          `,
        result (id) {
          deleteQuery('PersonFind', 'PersonDelete', this.PersonFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
